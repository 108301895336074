import axios from "axios";
import authHeader from "../services/header";

export const File = {
  // downloading : false,
  download(url, name, type, params, callback = null) {
    /**
     * url : 파일 다운로드 url
     * name : 파일명
     * type : 파일 확장자
     */
    const downalodFile = `${name}.${type}`;
    // console.log('file.js 시작' , this.downloading)
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
      headers: authHeader(),
      params: params,
      // title: header,
    })
      .then((response) => {
        const responseUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        // console.log(new Blob([response.data]));
        const responseLink = document.createElement("a");
        responseLink.href = responseUrl;
        responseLink.setAttribute("download", downalodFile);

        document.body.appendChild(responseLink);
        responseLink.click();
        responseLink.remove();
        window.URL.revokeObjectURL(new Blob([response.data])); //메모리 누수 방지?
        // this.downloading = true
        // console.log('file.js 다운', this.downloading);
        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        // this.downloading = false;
        if (callback) {
          callback(error);
        }
      });
  },
};

export default File;
